<template>
	<div>
		<TablesDefault
			v-model:query="computedQuery"
			v-model:currentFilterValues="currentFilterValues"
			:headers="headers"
			:records="store.instances[instanceLabel].queryData"
			:count="store.activeCount[instanceLabel]"
			:filters="tabs"
			:use-search="useSearch"
			:per-page="store.instances[instanceLabel].perPage"
			:current-page="store.instances[instanceLabel].currentPage"
			:current-sort="store.instances[instanceLabel]?.sort"
			:has-next-page="store.hasNextPage(instanceLabel)"
			:is-loading="store.showLoading(instanceLabel)"
			:has-completed-first-search="store.instances[instanceLabel]?.hasCompletedFirstSearch"
			@previous="store.previousPage(instanceLabel)"
			@next="store.nextPage(instanceLabel)"
			@sort="store.setSort(instanceLabel, {[$event[0]]: $event[1]})"
		>
			<template #default>
				<PropertiesRow v-for="record in store.instances[instanceLabel].queryData" :record="record" @click="store.openPanel(record.id)" />
			</template>
		</TablesDefault>
	</div>
</template>
<script setup lang="ts">

import {computed, onBeforeMount, ref} from "vue";
import {useProperties} from "~/stores/properties";

const props = defineProps({
	query: {
		type: String,
		required: false,
		default: ""
	},
	instanceLabel: {
		type: String,
		required: true
	},
	useSearch: {
		type: Boolean,
		required: false,
		default: true
	}
});

const store = useProperties();
const openRecord = ref(null as Record<string, undefined> | null);
const emit = defineEmits(['update:query']);

const computedQuery = computed({
	get() {
		return props.query;
	},
	set(value) {
		emit('update:query', value);
	}
});

const intl = new Intl.NumberFormat('en-GB');
const refreshCounts = () => {
	return props.useSearch ? {
		managed: {
			title: "Managed",
			options: [
				{label: `All (${intl.format(store.count(props.instanceLabel))})`, id: undefined},
				{label: `Managed (${intl.format(store.count(props.instanceLabel, 'managed', 'true'))})`, id: true},
				{label: `Non-managed (${intl.format(store.count(props.instanceLabel, 'managed', 'false'))})`, id: false},
			]
		}
	} : {};
};
const tabs = ref(refreshCounts());

onBeforeMount(() => {
	store.search(props.instanceLabel, props.query);
});

const currentFilterValues = computed({
	get() {
		return {
			managed: store.instances[props.instanceLabel].queryFacets.managed ?? undefined
		}
	},
	set(value) {
		store.setFacet(props.instanceLabel, 'managed', value.managed);
	}
});

const headers = [
	{
		label: "Address line 1",
		id: "address1"
	},
	{
		label: "Address line 2",
		id: "address2"
	},
	{
		label: "City",
		id: "city"
	},
	{
		label: "County",
		id: "county"
	},
	{
		label: "Postcode",
		id: "postcode"
	},
	{
		label: "Managed",
		id: "managed"
	},
];

watch(() => props.query, () => {
	store.searchAndResetPage(props.instanceLabel, props.query)
});

watch(() => store.instances[props.instanceLabel].countFetchedAt, () => {
	tabs.value = refreshCounts();
});
watch(() => store.currentRecord, () => {
	if (store.currentRecord) {
		openRecord.value = store.currentRecord;
	}
});
</script>
